import RainbowSpan from "../components/Functional/RainbowSpan/RainbowSpan";

export const selectedSkills = ['TypeScript', 'Preact'];
export const additional = [
    <p>and I'm very bad at design</p>,
    <p>i try to learn new and <RainbowSpan>interesting</RainbowSpan> things</p>
];

export default [
    'JavaScript',
    'TypeScript',
    'Python',
    'Discord.JS',
    'HTML',
    'CSS',
    'EJS',
    'React',
    'Preact',
    'MongoDB',
    'Git'
];