export default [
    [
        'https://life.mirdukkkkk.space',
        'My Pixel Battle'
    ],
    [
        'https://source.mirdukkkkk.space',
        'This website repo'
    ],
    [
        'https://github.mirdukkkkk.space',
        'GitHub'
    ],
    [
        'https://discord.mirdukkkkk.space',
        'Discord'
    ],
    [
        'https://youtube.mirdukkkkk.space',
        'YouTube'
    ]
];